import GlobalStateService from 'services/GlobalStateService';

export default class BaseService {

    static async callAPI(url, data) {
        let jwt = null;
        const loginDetails = GlobalStateService.getValue('loginDetails');
        if (loginDetails) {
            jwt = loginDetails.jwt;
        }
        try {
            data = { ...data };
            const opt = {
                method: (data.method || 'POST'),
                headers: {
                    'Content-Type': 'application/json',
                    'X-Salon-Code': (GlobalStateService.getValue('salonCode') || ''),
                    'X-Salon-Group-Code': (GlobalStateService.getValue('salonGroupCode') || ''),
                    'X-JWT': jwt
                }
            };

            if (data) {
                if (data.salonCode) {
                    delete data.salonCode;
                }
                if (data.jwt) {
                    delete data.jwt;
                }
                if (data.method) {
                    delete data.method;
                }
            }
            if ((opt.method || '').toLowerCase() != 'get') {
                opt.body = JSON.stringify(data);
            }
            const response = await fetch('/api/' + url, opt);
            const text = await response.text();
            let responseObject = null;
            if (text.length > 0) {
                responseObject = JSON.parse(text);
            }

            switch (response.status) {
                case 200:
                case 400:
                    if (responseObject) {
                        if (responseObject.success) {
                            return responseObject.content;
                        } else {
                            throw responseObject.content; // Error message
                        }
                    } else {
                        return null;
                    }
                    break;
                case 401:
                case 403:
                case 500:
                    if (responseObject) {
                        throw responseObject.content || 'Unknown error'; // Error message
                    } else {
                        return 'Unknown error';
                    }
                    break;
            }

        } catch (error) {
            throw error;
        }
    }

}
