// Libs
import React, { Component } from 'react';

// Services & Helpers
import TextHelpers from 'helpers/TextHelpers';
import GlobalStateService from 'services/GlobalStateService';
import OnlineBookingService from 'services/OnlineBookingService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';

//-------------------------------------------------------------------------------------------------------------------

class SelectServicesSubPage extends Component {

    constructor(props) {
        super(props);

        this.salonCode = GlobalStateService.getValue('salonCode');

        this.state = {
            selectingStylistServiceID: null
        };
    }

    //--------------------------------------------------------------------------------------------------------------------

    async componentDidMount() {
        const booking = { ...this.props.booking };
        if (booking.date || booking.time) {
            delete booking.date;
            delete booking.time;
            this.props.updateBooking(booking, () => {
                this.props.restoreOriginalBooking();
            });
        } else {
            this.props.restoreOriginalBooking();
        }

        await this.props.onLoad();

        let oldBooking = OnlineBookingService.getLocalStorage('rebook-' + this.salonCode, {});
        if (oldBooking?.appointmentServices?.length) {
            function findService(serviceCategories, serviceID) {
                for (var i = 0; i < serviceCategories.length; i++) {
                    for (var j = 0; j < serviceCategories[i].services.length; j++) {
                        if (serviceCategories[i].services[j].serviceID == serviceID) {
                            return serviceCategories[i].services[j];
                        }
                    }
                }
            }

            function findPackage(serviceCategories, packageID) {
                for (var i = 0; i < serviceCategories.length; i++) {
                    for (var j = 0; j < serviceCategories[i].packages.length; j++) {
                        if (serviceCategories[i].packages[j].packageID == packageID) {
                            return serviceCategories[i].packages[j];
                        }
                    }
                }
            }
            while (booking.packages.length) {
                this.props.removePackage(booking.packages[0]);
            }
            while (booking.services.length) {
                this.props.removeService(booking.services[0]);
            }

            for (var i = 0; i < oldBooking.appointmentPackages.length; i++) {
                const pkg = findPackage(this.props.serviceCategories, oldBooking.appointmentPackages[i].package.packageID);
                if (!pkg) {
                    BootboxHelper.alert('Sorry, ' + oldBooking.appointmentPackages[i].package.name + ' is no longer available.');
                } else {
                    this.props.addOrUpdateService(pkg, false, null);
                }
            }

            for (var i = 0; i < oldBooking.appointmentServices.length; i++) {
                if (!oldBooking.appointmentServices[i].appointmentPackageID &&
                    !oldBooking.appointmentServices[i].service.isAddedAutomatically) {
                    const service = findService(this.props.serviceCategories, oldBooking.appointmentServices[i].service.serviceID);
                    if (!service) {
                        BootboxHelper.alert('Sorry, ' + oldBooking.appointmentServices[i].service.name + ' is no longer available.');
                    } else {
                        service.stylist = {
                            nickname: oldBooking.appointmentServices[i].stylistName,
                            userID: oldBooking.appointmentServices[i].stylistUserID
                        }
                        this.props.addOrUpdateService(service, false, null);
                    }
                }
            }

            OnlineBookingService.setLocalStorage('rebook-' + this.salonCode, null);
        }

    }

    updateServiceCategoryField(index, field, value) {
        const serviceCategories = [...this.state.serviceCategories];
        const serviceCategory = { ...serviceCategories[index] };
        serviceCategory[field] = value;
        serviceCategories[index] = serviceCategory;
        this.setState({
            serviceCategories: serviceCategories
        });
    }

    selectServiceCategory(serviceCategoryID) {
        const selectedServiceCategory = this.props.serviceCategories.find(sc => sc.serviceCategoryID == serviceCategoryID);
        this.setState({
            selectedServiceCategory
        }, () => {
            if (window.matchMedia('(max-width: 1000px)').matches) {
                const headerElement = document.querySelector('.select-services-right');
                if (headerElement) {
                    headerElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        });
    }
    
    toggleSelectingStylist(serviceOrPackage, removeIfAdded) {
        // Toggle off
        if (serviceOrPackage.serviceID) {
            const isSelected = this.props.booking.services.findIndex(bs => bs.serviceID == serviceOrPackage.serviceID) != -1;
            if (isSelected && removeIfAdded) {
                this.props.removeService(serviceOrPackage);
                return;
            } else if (this.state.selectingStylistServiceID == serviceOrPackage.serviceID) {
                this.setState({
                    selectingStylistServiceID: null
                });
                return;
            }
        }
        else if (serviceOrPackage.packageID) {
            const isSelected = this.props.booking.packages.findIndex(bp => bp.packageID == serviceOrPackage.packageID) != -1;
            if (isSelected && this.state.selectingStylistPackageID == serviceOrPackage.packageID && removeIfAdded) {
                this.props.removePackage(serviceOrPackage);
                this.setState({
                    selectingStylistPackageID: null
                });
                return;
            }
        }

        this.setState({
            selectingStylistServiceID: (serviceOrPackage ? serviceOrPackage.serviceID : null),
            selectingStylistPackageID: (serviceOrPackage ? serviceOrPackage.packageID : null)
        });
        if (serviceOrPackage.packageID) {
            const isSelected = this.props.booking.packages.findIndex(bp => bp.packageID == serviceOrPackage.packageID) != -1;
            if (!isSelected) {
                this.selectPackage(serviceOrPackage.packageID);
            }
        }
    }

    selectServiceAndStylist(id, stylist) {
        const index = this.state.selectedServiceCategory.services.findIndex(s => s.serviceID == id);
        const service = { ... this.state.selectedServiceCategory.services[index] };
        service.stylist = stylist;
        this.props.addOrUpdateService(service, false, null);
        this.setState({
            selectingStylistServiceID: null
        });
    }

    selectStylistForPackage(id, serviceID, stylist) {
        const pkg = this.props.booking.packages.find(bp => bp.packageID == id);
        if (pkg) {
            if (!pkg.stylists) {
                pkg.stylists = {};
            }
            pkg.stylists[serviceID] = stylist;
        }
        this.props.addOrUpdateService(pkg, false, null);

        //const pkg = findPackage(this.props.serviceCategories, oldBooking.appointmentPackages[i].package.packageID);
        //if (!pkg) {
        //    BootboxHelper.alert('Sorry, ' + oldBooking.appointmentPackages[i].package.name + ' is no longer available.');
        //} else {
        //    this.props.addOrUpdateService(pkg, false, null);
        //}
    }

    selectPackage(id) {
        const index = this.state.selectedServiceCategory.packages.findIndex(p => p.packageID == id);
        const pkg = { ...this.state.selectedServiceCategory.packages[index] };
        this.props.addOrUpdateService(pkg, false, null);
        this.setState({
            selectingStylistServiceID: null
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (

            <div className="select-services">

                <div className="select-services-left">

                    <div className="panel">

                        <div className="panel-header">
                            Categories
                        </div>

                        <div className="panel-body">

                            {this.renderServiceCategoryList()}

                        </div>

                    </div>

                </div>

                <div className="select-services-right">

                    <div className="panel">

                        <div className="panel-header">
                            Select Services
                        </div>

                        <div className="panel-body">

                            {this.renderServiceAndPackageList()}

                        </div>

                    </div>

                </div>

            </div>

        );
    }

    renderServiceCategoryList() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <ul className="service-category-list">

                {this.props.serviceCategories.map((sc, index) =>
                    <React.Fragment key={sc.serviceCategoryID}>
                        {this.renderServiceCategory(sc, index)}
                    </React.Fragment>
                )}

            </ul>

        </>);
    }

    renderServiceCategory(serviceCategory, index) {
        const {
            selectedServiceCategory
        } = this.state;
        const isSelected = (selectedServiceCategory && selectedServiceCategory.serviceCategoryID == serviceCategory.serviceCategoryID);
        return (
            <li
                className={'service-category hollow-button ' + (isSelected ? 'selected' : '')}
                onClick={e => this.selectServiceCategory(serviceCategory.serviceCategoryID)}
            >
                <div className="service-category-name">
                    {serviceCategory.name}
                </div>
            </li>
        );
    }

    renderServiceAndPackageList() {
        const {
            isLoading,
            selectedServiceCategory
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        if (!selectedServiceCategory) {
            return (
                <div className="select-service-category">
                    Please select a service category...
                </div>
            );
        }

        // Combine service & package list
        const servicesAndPackages = selectedServiceCategory.services.concat(selectedServiceCategory.packages);
        servicesAndPackages.sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)));

        return (
            <ul className="service-list">
                {servicesAndPackages.map((sp, index) =>
                    <React.Fragment key={sp.serviceID ? `service-${sp.serviceID}` : `package-${sp.packageID}`}>
                        {sp.serviceID ?
                            this.renderService(sp, index) :
                            this.renderPackage(sp, index)
                        }
                    </React.Fragment>
                )}
            </ul>
        );
    }

    renderService(service, index) {
        const {
            selectingStylistServiceID
        } = this.state;
        const currencyOpts = {
            numDP: 2,
            includeSymbol: true,
            currency: this.props.salonInfo.currency
        };

        const isSelected = this.props.booking.services.findIndex(bs => bs.serviceID == service.serviceID) != -1;
        const isSelectingStylist = selectingStylistServiceID == service.serviceID;

        return (
            <li className="service">
                <div className="service-heading-description" onClick={e => this.toggleSelectingStylist(service, true)}>
                    <div className="service-heading">
                        <div className="service-name">
                            {service.name}
                        </div>
                        <div className="service-price-duration">
                            {service.isPriceOnConsultation ? <>
                                Price on consultation
                            </> : <>
                                {service.minPrice != service.maxPrice &&
                                    <>From{' '}</>
                                }
                                <span>{TextHelpers.formatCurrency(service.minPrice, currencyOpts)}</span>
                            </>}
                            {' - '}
                            {TextHelpers.formatDuration(service.durationMins)}
                        </div>
                        <div
                            className={'checkbox service-checkbox ' + (isSelected ? 'checked' : '')}
                        ></div>
                    </div>
                    {service.description &&
                        <div className="service-description">
                            {service.description}
                        </div>
                    }
                </div>

                {service.mustPayUpfront && isSelected &&
                    <div className="must-pay-upfront">
                        * Please note - full upfront payment is needed to book this service
                    </div>
                }

                {/* Chosen stylist */}
                {isSelected && !isSelectingStylist &&
                    this.renderStylistButton(service)
                }

                {/* List of stylists */}
                {isSelectingStylist &&
                    this.renderServiceStylistList(service)
                }

            </li>
        );
    }

    renderPackage(pkg) {
        const {
            selectingStylistPackageID
        } = this.state;
        const currencyOpts = {
            numDP: 2,
            includeSymbol: true,
            currency: this.props.salonInfo.currency
        };
        const isSelected = this.props.booking.packages.findIndex(bs => bs.packageID == pkg.packageID) != -1;
        const isSelectingStylist = selectingStylistPackageID == pkg.packageID;

        return (
            <li className="service">
                <div className="service-heading-description">
                    <div className="service-heading" onClick={e => this.toggleSelectingStylist(pkg, true)}>
                        <div className="service-name">
                            {pkg.name}
                        </div>
                        <div className="service-price-duration">
                            {pkg.isPriceOnConsultation ? <>
                                Price on consultation
                            </> : <>
                                {pkg.minPrice != pkg.maxPrice &&
                                    <>From{' '}</>
                                }
                                <span>{TextHelpers.formatCurrency(pkg.minPrice, currencyOpts)}</span>
                            </>}
                            {' - '}
                            {TextHelpers.formatDuration(pkg.durationMins)}
                        </div>
                        <div
                            className={'checkbox service-checkbox ' + (isSelected ? 'checked' : '')}
                        />
                    </div>
                    {pkg.description &&
                        <div className="service-description">
                            {pkg.description}
                        </div>
                    }
                </div>

                {/*
                {service.mustPayUpfront && isSelected &&
                    <div className="must-pay-upfront">
                        * Please note - full upfront payment is needed to book this service
                    </div>
                }*/}

                {/* List of stylists */}
                {this.renderPackageServiceList(pkg, isSelectingStylist)}
            </li>
        );
    }

    renderServiceStylistList(service) {
        const bookingService = this.props.booking.services.find(bs => bs.serviceID == service.serviceID);
        const currencyOpts = {
            numDP: 2,
            includeSymbol: true,
            currency: this.props.salonInfo.currency
        };

        return (
            <ul className="stylist-list">
                <li className="stylist" onClick={e => this.selectServiceAndStylist(service.serviceID, null)}>
                    <div
                        className={'checkbox stylist-checkbox ' + (bookingService && !bookingService.stylist ? 'checked' : '')}
                    ></div>
                    <div className="stylist-name">
                        Any Stylist
                    </div>
                </li>

                {service.stylists.map(stylist =>
                    <li
                        key={stylist.userID}
                        className="stylist"
                        onClick={e => this.selectServiceAndStylist(service.serviceID, stylist)}
                    >
                        <div
                            className={'checkbox stylist-checkbox ' + (bookingService && bookingService.stylist && bookingService.stylist.userID == stylist.userID ? 'checked' : '')}
                        ></div>
                        <div className="stylist-name">
                            {stylist.nickname}
                            {stylist.serviceBandName &&
                                <div className="stylist-service-band">{stylist.serviceBandName}</div>
                            }
                        </div>
                        <div className="stylist-price">
                            {
                                service.isPriceOnConsultation ? '' :
                                    TextHelpers.formatCurrency(service.byStylist[stylist.userID].price, currencyOpts)
                            }
                        </div>
                    </li>
                )}
            </ul>
        );
    }

    renderPackageServiceList(pkg, isSelectingStylist) {
        const bookingPkg = this.props.booking.packages.find(bp => bp.packageID == pkg.packageID);
        return (
            <ul className={`package-service-list ${isSelectingStylist ? 'is-open' : ''}`}>
                {pkg.services.map(s =>
                    <li key={s.serviceID}>
                        <label>
                            {s.name}

                            {isSelectingStylist &&
                                <select
                                    value={bookingPkg && bookingPkg.stylists ? bookingPkg.stylists[s.serviceID]?.userID : ''}
                                    onChange={e => {
                                        const stylist = s.stylists.find(s => s.userID == e.target.value);
                                        this.selectStylistForPackage(pkg.packageID, s.serviceID, stylist);
                                    }}
                                >
                                    <option value="">Any stylist</option>
                                    {s.stylists.map(stylist =>
                                        <option key={stylist.userID} value={stylist.userID}>
                                            {stylist.nickname}
                                        </option>
                                    )}
                                </select>
                            }
                        </label>
                    </li>
                )}
            </ul>
        );
    }

    renderStylistButton(service) {
        const bookingService = this.props.booking.services.find(bs => bs.serviceID == service.serviceID);

        return (
            <button
                className="button primary select-stylist-button"
                onClick={e => this.toggleSelectingStylist(service, false)}
            >
                {bookingService.stylist && <>
                    {bookingService.stylist.nickname}
                </>}
                {bookingService.stylist === undefined && <>
                    Select stylist
                </>}
                {bookingService.stylist === null && <>
                    Any Stylist
                </>}
            </button>
        );
    }

}

export default SelectServicesSubPage;