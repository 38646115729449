// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

//-------------------------------------------------------------------------------------------------------------------

class SelectDateSubPage extends Component {

    constructor(props) {
        super(props);

        this.selectDate = this.selectDate.bind(this);
        this.updateSlotPickerHeight = this.updateSlotPickerHeight.bind(this);

        this.state = {
            isLoadingMonth: true,
            infoByDate: {}
        };
    }

    //--------------------------------------------------------------------------------------------------------------------

    componentDidMount() {
        this.load();
        window.addEventListener('resize', this.updateSlotPickerHeight);
        this.props.removeSpecificBookingInfo();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSlotPickerHeight);
    }

    async load() {
        const {
            booking
        } = this.props;
        const date = booking.date || moment().startOf('day').format('YYYY-MM-DD');
        await this.loadMonth(date);
        this.selectDate(date);
    }

    async loadMonth(date) {
        return new Promise(async (resolve) => {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const infoByDate = await OnlineBookingService.getDateInfo(year, month);
            this.setState({
                infoByDate,
                isLoadingMonth: false,
                currentMonth: month,
                currentYear: year
            }, resolve);
        });
    }

    async selectDate(date) {
        // Load month if different to currently loaded month
        const year = date.substring(0, 4);
        const month = date.substring(5, 7);
        if (year != this.state.currentYear || month != this.state.currentMonth) {
            this.setState({
                currentMonth: month,
                currentYear: year
            }, () => {
                this.loadMonth(date);
            });
        }

        // Update booking
        const booking = { ...this.props.booking };
        booking.date = date;
        this.props.updateBooking(booking);

        // Set loading
        this.setState({
            isLoadingSlots: true
        });

        // Get available slots
        const slots = await OnlineBookingService.listSlotAvailability(date, this.props.booking);

        // De-select time if not available on this day
        if (!slots.find(s => s == this.props.booking.time)) {
            this.selectTime(null);
        }

        // Update UI
        this.setState({
            slots,
            isLoadingSlots: false
        });
    }

    async selectTime(time) {
        const booking = { ...this.props.booking };
        booking.time = time;
        this.props.updateBooking(booking, () => {
            if (!time) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        });
    }

    async goToNextAvailableDate() {
        if (this.state.isLoadingNextAvailableDate) {
            return;
        }
        this.setState({
            isLoadingNextAvailableDate: true
        });
        const response = await OnlineBookingService.getNextAvailableDate(this.props.booking.date, this.props.booking);
        if (response && response.date) {
            this.selectDate(response.date);
        } else if (response && response.message) {
            BootboxHelper.alert(response.message);
        } else {
            BootboxHelper.alert('Sorry, we couldn\'t find any available days in the next 12 months.');
        }
        this.setState({
            isLoadingNextAvailableDate: false
        });
    }

    updateSlotPickerHeight() {
        const datePicker = document.querySelector('.date-picker');
        const slotPickerList = document.querySelector('.slot-picker .inner ul');
        const controlPanel = document.querySelector('.control-panel');
        if (!datePicker || !slotPickerList || !controlPanel) {
            return;
        }
        let maxHeight = datePicker.getBoundingClientRect().height - (controlPanel.getBoundingClientRect().height + 90);
        if (maxHeight < 220) maxHeight = 220;
        slotPickerList.style.maxHeight = `${maxHeight}px`;
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (
            <div className="panel">

                <div className="panel-header bg-colour-3">
                    Select Date &amp; Time
                </div>

                <div className="panel-body">

                    {this.renderInner()}

                </div>

            </div>
        );
    }

    renderInner() {
        const {
            booking
        } = this.props;
        const {
            infoByDate,
            isLoadingMonth,
            isLoadingNextAvailableDate
        } = this.state;


        var maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + this.props.salonInfo.maxMonthsAhead);
        // Set max height on slot picker
        setTimeout(() => this.updateSlotPickerHeight(), 0);

        return (<>

            <div className="date-slot-picker">

                <div className="date-picker">

                    {isLoadingMonth ? <Loader /> :
                        <DatePicker
                            minDate={new Date()}
                            maxDate={maxDate}
                            selected={booking.date ? moment(booking.date).toDate() : null}
                            inline
                            calendarStartDay={1}
                            onChange={date => {
                                date = moment(date).format('YYYY-MM-DD');
                                this.selectDate(date);
                            }}
                            onMonthChange={date => this.loadMonth(date)}
                            dayClassName={(date) => {
                                //const dateKey = moment(date).format('YYYY-MM-DD');
                                //if (infoByDate) {
                                //    const info = infoByDate[dateKey];
                                //    if (info && !info.isOpen) {
                                //        return 'date-picker-salon-closed';
                                //    }
                                //}
                                return '';
                            }}
                        />
                    }

                </div>

                <div className="slot-picker">

                    <div className="inner">
                        <div className="header">
                            {!!booking.date && moment(booking.date).format('dddd, Do MMMM YYYY')}
                        </div>
                        {this.renderSlots()}
                    </div>

                    <div className="control-panel">

                        <button className="button primary" onClick={() => this.goToNextAvailableDate()}>
                            {isLoadingNextAvailableDate ?
                                <Loader isInline /> :
                                <>Find next available date</>
                            }
                        </button>

                        <button className="button secondary" onClick={() => this.props.goPrev()}>
                            Choose different stylist
                        </button>

                    </div>

                </div>

            </div>


        </>);
    }

    renderSlots() {
        const {
            booking
        } = this.props;
        const {
            isLoadingSlots,
            slots
        } = this.state;

        if (isLoadingSlots || !slots) {
            return (<Loader />);
        }

        if (slots.length == 0) {
            return (
                <div className="sorry">
                    Sorry, we couldn't find any <br />
                    availability for that day.
                </div>
            );
        }

        return (
            <ul>
                {slots.map(time =>
                    <li key={time} onClick={() => this.selectTime(time)}>
                        <div
                            className={`checkbox ${booking.time == time ? 'checked' : ''}`}
                        ></div>
                        {TextHelpers.formatTime(time)}
                    </li>
                )}
            </ul>
        );
    }

}

export default SelectDateSubPage;