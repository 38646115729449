// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// Services & Helpers
import OnlineBookingService from 'services/OnlineBookingService';
import GlobalStateService from 'services/GlobalStateService';
import TextHelpers from 'helpers/TextHelpers';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import Loader from 'components/reusable/Loader';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

//-------------------------------------------------------------------------------------------------------------------

class ReschedulePanel extends Component {

    constructor(props) {
        super(props);

        this.selectDate = this.selectDate.bind(this);
        this.updateSlotPickerHeight = this.updateSlotPickerHeight.bind(this);
        this.save = this.save.bind(this);

        this.salonCode = GlobalStateService.getValue('salonCode');

        this.state = {
            isLoadingMonth: true,
            infoByDate: {}
        };
    }

    //--------------------------------------------------------------------------------------------------------------------

    componentDidMount() {
        this.load();
        window.addEventListener('resize', this.updateSlotPickerHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSlotPickerHeight);
    }

    async load() {
        let oldBooking = OnlineBookingService.getLocalStorage('reschedule-' + this.salonCode, {});
        let booking = {
            packages: [],
            services: []
        };

        for (var i = 0; i < oldBooking.appointmentServices.length; i++) {
            booking.services.push({
                serviceID: oldBooking.appointmentServices[i].service.serviceID,
                service: { serviceID: oldBooking.appointmentServices[i].service.serviceID },
                stylist: { userID: oldBooking.appointmentServices[i].stylistUserID }
            });
        }

        await this.setState({
            oldBooking,
            booking
        });
        const date = moment().startOf('day').format('YYYY-MM-DD');
        await this.loadMonth(date);
        this.selectDate(date);
    }

    async loadMonth(date) {
        return new Promise(async (resolve) => {
            const year = date.substring(0, 4);
            const month = date.substring(5, 7);
            const infoByDate = await OnlineBookingService.getDateInfo(year, month);
            this.setState({
                infoByDate,
                isLoadingMonth: false,
                currentMonth: month,
                currentYear: year
            }, resolve);
        });
    }

    async selectDate(date) {
        // Load month if different to currently loaded month
        const year = date.substring(0, 4);
        const month = date.substring(5, 7);
        if (year != this.state.currentYear || month != this.state.currentMonth) {
            this.setState({
                currentMonth: month,
                currentYear: year
            }, () => {
                this.loadMonth(date);
            });
        }

        // Update booking
        const booking = { ...this.state.booking };
        booking.date = date;

        // Set loading
        this.setState({
            isLoadingSlots: true
        });

        // Get available slots
        const slots = await OnlineBookingService.listSlotAvailability(date, this.state.booking);

        // De-select time if not available on this day
        if (!slots.find(s => s == this.state.booking.time)) {
            this.selectTime(null);
        }

        // Update UI
        this.setState({
            booking,
            slots,
            isLoadingSlots: false
        });
    }

    async selectTime(time) {
        const booking = { ...this.state.booking };
        booking.time = time;
        if (!time) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        this.setState({
            booking
        });
    }

    async goToNextAvailableDate() {
        if (this.state.isLoadingNextAvailableDate) {
            return;
        }
        this.setState({
            isLoadingNextAvailableDate: true
        });
        const response = await OnlineBookingService.getNextAvailableDate(this.state.booking.date, this.state.booking);
        if (response && response.date) {
            this.selectDate(response.date);
        } else {
            BootboxHelper.alert('Sorry, we couldn\'t find any available days in the next 12 months.');
        }
        this.setState({
            isLoadingNextAvailableDate: false
        });
    }

    updateSlotPickerHeight() {
        const datePicker = document.querySelector('.date-picker');
        const slotPickerList = document.querySelector('.slot-picker .inner ul');
        const controlPanel = document.querySelector('.control-panel');
        if (!datePicker || !slotPickerList || !controlPanel) {
            return;
        }
        let maxHeight = datePicker.getBoundingClientRect().height - (controlPanel.getBoundingClientRect().height + 90);
        if (maxHeight < 220) maxHeight = 220;
        slotPickerList.style.maxHeight = `${maxHeight}px`;
    }

    async save() {
        await this.setState({ loading: true });
        await OnlineBookingService.rescheduleAppt(this.state.oldBooking.appointmentID, this.state.booking);
        this.props.history.push('/account');
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    render() {
        return (
            <div className="panel">

                <div className="panel-header bg-colour-3">
                    Select Date &amp; Time
                </div>

                <div className="panel-body">

                    {this.renderInner()}

                </div>

            </div>
        );
    }

    renderInner() {
        const {
            loading,
            booking,
            infoByDate,
            isLoadingMonth,
            isLoadingNextAvailableDate
        } = this.state;


        var maxDate = new Date();
        maxDate.setMonth(maxDate.getMonth() + this.props.salonInfo.maxMonthsAhead);
        // Set max height on slot picker
        setTimeout(() => this.updateSlotPickerHeight(), 0);

        if (!booking || loading) {
            return (<Loader />);
        }

        return (<>

            <div className="date-slot-picker">

                <div className="date-picker">

                    {isLoadingMonth ? <Loader /> :
                        <DatePicker
                            minDate={new Date()}
                            maxDate={maxDate}
                            selected={booking.date ? moment(booking.date).toDate() : null}
                            inline
                            calendarStartDay={1}
                            onChange={date => {
                                date = moment(date).format('YYYY-MM-DD');
                                this.selectDate(date);
                            }}
                            onMonthChange={date => this.loadMonth(date)}
                            dayClassName={(date) => {
                                const dateKey = moment(date).format('YYYY-MM-DD');
                                if (infoByDate) {
                                    const info = infoByDate[dateKey];
                                    if (info && !info.isOpen) {
                                        return 'date-picker-salon-closed';
                                    }
                                }
                                return '';
                            }}
                        />
                    }

                </div>

                <div className="slot-picker">

                    <div className="inner">
                        <div className="header">
                            {!!booking.date && moment(booking.date).format('dddd, Do MMMM YYYY')}
                        </div>
                        {this.renderSlots()}
                    </div>

                    <div className="control-panel">

                        <button className="button secondary" onClick={() => this.goToNextAvailableDate()}>
                            {isLoadingNextAvailableDate ?
                                <Loader isInline /> :
                                <>Find next available date</>
                            }
                        </button>

                        <button className="button primary" onClick={() => this.save()} disabled={!this.state.booking.time}>
                            Save
                        </button>

                    </div>

                </div>

            </div>


        </>);
    }

    renderSlots() {
        const {
            booking,
            isLoadingSlots,
            slots
        } = this.state;

        if (isLoadingSlots || !slots) {
            return (<Loader />);
        }

        if (slots.length == 0) {
            return (
                <div className="sorry">
                    Sorry, we couldn't find any <br />
                    availability for that day.
                </div>
            );
        }

        return (
            <ul>
                {slots.map(time =>
                    <li key={time} onClick={() => this.selectTime(time)}>
                        <div
                            className={`checkbox ${booking.time == time ? 'checked' : ''}`}
                        ></div>
                        {TextHelpers.formatTime(time)}
                    </li>
                )}
            </ul>
        );
    }

}

export default ReschedulePanel;